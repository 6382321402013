// src/app/dim-instances/dim-instances.component.ts
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DimInstanceModel } from '../models/dim-instance.model';
import { Router } from '@angular/router';
import { DimInstanceService } from '../services/dim-instance.service';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-dim-instances',
  templateUrl: './dim-instances.component.html',
  styleUrls: ['./dim-instances.component.scss']
})
export class DimInstancesComponent implements OnInit {

  dimInstanceModels: DimInstanceModel[] = [];
  currentPage = 1; 
  itemsPerPage = 10; 

  constructor(
    private router: Router,
    private dimInstanceService: DimInstanceService,
    private dialogService: NbDialogService) { }

  ngOnInit(): void {
    this.getDimInstances();
  }

  getDimInstances() {
    this.dimInstanceService.getDimInstances().subscribe(data => {
      this.dimInstanceModels = data;

      this.dimInstanceModels.forEach(x => {
        if (x.lastCollected != null) {
          let utcDate = new Date(x.lastCollected! + 'Z');
          let localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
          x.lastCollectedString = this.convertDate(localDate);
        } 
      })
    });
  }

  add() {
    this.router.navigate(['AddEdit', '0']);
  }

  edit(dimInstanceModel: DimInstanceModel) {
    this.router.navigate(['AddEdit', dimInstanceModel.dimInstanceKey]);
  }

  onDelete(id: any, dialog: TemplateRef<any>) {
    this.dialogService.open(
      dialog, { context: id }
    )
  }

  delete(event: any, dialog: any) {
    this.dimInstanceService.deleteDimInstance(event).subscribe(response => {
      dialog.close();
    });        
  }

  convertDate(date: Date) {
    if (date == null) return null;
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    return yyyy + '/' + mm + '/' + dd;
  }
}
