import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username!: string;
  password!: string;  

  constructor(private authService: AuthService,
    private router: Router,
    private toastrService: NbToastrService) { }

  ngOnInit(): void {
  }

  login() {   
    this.authService.login(this.username, this.password).subscribe(Response => {
      this.toastrService.success('Successful login.');
        console.log('Login successful');
        this.router.navigate(['Grid']);
      },
      (error) => {
        this.toastrService.danger('Incorrect credentials. Failed to log in.');
        console.log('Login error:', error);        
    });    
  }
}
