<nb-layout>
    <nb-layout-column id="login" class="wrap">
        <nb-card>
            <nb-card-header>
                Central Repo Login
            </nb-card-header>.
            <nb-card-body>
                <form id="login-form" name="login-form" #loginForm='ngForm'>
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <input nbInput class="form-control" id="username" name="username"
                                        placeholder="Username" [(ngModel)]="username" fullWidth required ngModel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="password" nbInput class="form-control" id="password"
                                        placeholder="Password" name="password" [(ngModel)]="password" fullWidth
                                        required>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </nb-card-body>
            <nb-card-footer>
                <div>
                    <a class="login" (click)="login()">Sign in &gt;</a>
                </div>
            </nb-card-footer>
        </nb-card>
    </nb-layout-column>
</nb-layout>
