import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DimInstanceModel } from "../models/dim-instance.model";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class DimInstanceService {

    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getDimInstances(): Observable<any> {
        let headers = this.authService.getHeaders();
        return this.http.get(`${this.apiUrl}/diminstances`, { headers });
    }

    getDimInstance(id: number): Observable<any> {
        let headers = this.authService.getHeaders();
        return this.http.get(`${this.apiUrl}/diminstances/${id}`, { headers })
    }

    getProviders(): Observable<any> {
        let headers = this.authService.getHeaders();
        return this.http.get(`${this.apiUrl}/diminstances/getdimproviders`, { headers })
    }

    getPlatforms(): Observable<any> {
        let headers = this.authService.getHeaders();
        return this.http.get(`${this.apiUrl}/diminstances/getdimplatforms`, { headers })
    }

    getClients(): Observable<any> {
        let headers = this.authService.getHeaders();
        return this.http.get(`${this.apiUrl}/diminstances/getdimclients`, { headers })
    }

    addDimInstance(dimInstance: DimInstanceModel): Observable<any> {
        let headers = this.authService.getHeaders();
        return this.http.post(`${this.apiUrl}/diminstances`, dimInstance, { headers });
    }

    editDimInstance(dimInstance: DimInstanceModel): Observable<any> {
        let headers = this.authService.getHeaders();
        return this.http.post(`${this.apiUrl}/diminstances/${dimInstance.dimInstanceKey}`, dimInstance, { headers });
    }

    deleteDimInstance(id: number): Observable<any> {
        let headers = this.authService.getHeaders();
        return this.http.delete(`${this.apiUrl}/diminstances/${id}`, { headers });
    }
}