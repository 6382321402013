import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiUrl = environment.apiUrl;
    private _headers!: HttpHeaders;
    private credentials!: string;

    get headers(): HttpHeaders {
        return this._headers
    }   

    set headers(headers: HttpHeaders) {
        this._headers = headers;
    }

    constructor(private http: HttpClient) { }

    login(username: string, password: string): Observable<any> {
        this.credentials = btoa(`${username}:${password}`);
        localStorage.setItem('credentials', JSON.stringify(this.credentials));
        
        let headers = this.getHeaders();

        return this.http.post<any>(`${this.apiUrl}/account`, {}, { headers });
    }    

    getHeaders() {
        this.credentials = JSON.parse(localStorage.getItem('credentials')!);
        return new HttpHeaders().set('Authorization', `Basic ${this.credentials}`);        
    }
}