import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditComponent } from './dim-instances/add-edit/add-edit.component';
import { DimInstancesComponent } from './dim-instances/dim-instances.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'Grid', component: DimInstancesComponent },
  { path: 'AddEdit/:id', component: AddEditComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
