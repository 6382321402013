<nb-layout>
    <nb-layout-column>
        <nb-card>
            <nb-card-header>
                <app-header></app-header>
            </nb-card-header>
        </nb-card>
        <nb-card>
            <nb-card-header>
                {{this.isAdd ? 'Add' : 'Edit'}} Instance
            </nb-card-header>
            <nb-card-body>
                <form id="dimInstanceForm" name="dimInstanceForm" #dimInstanceForm="ngForm" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col-sm-3">
                            <label for="dimClients">Client</label>
                            <nb-select id="dimClients" name="dimClients" [(ngModel)]="dimInstance.dimClientKey"
                                fullWidth>
                                <nb-option *ngFor="let client of dimClients" [value]="client.dimClientKey">
                                    {{client.client}}
                                </nb-option>
                            </nb-select>
                        </div>                        
                        <div class="col-sm-3">
                            <label for="serverName">Server Name</label>
                            <input nbInput id="serverName" name="serverName" class="form-control"
                                [(ngModel)]="dimInstance.serverName" required fullWidth>
                        </div>
                        <div class="col-sm-3">
                            <label for="instance">Instance</label>
                            <input nbInput id="instance" name="instance" class="form-control"
                                [(ngModel)]="dimInstance.instance" required fullWidth>
                        </div>
                        <div class="col-sm-3">
                            <label for="environment">Environment</label>
                            <input nbInput id="environment" name="environment" class="form-control"
                                [(ngModel)]="dimInstance.environment" required fullWidth>
                        </div>
                        <div class="col-sm-3">
                            <label for="dimPlatforms">Platform</label>
                            <nb-select id="dimPlatforms" name="dimPlatforms" [(ngModel)]="dimInstance.dimPlatformKey"
                                required fullWidth>
                                <nb-option *ngFor="let platform of dimPlatforms" [value]="platform.dimPlatformKey">
                                    {{platform.platformName}}
                                </nb-option>
                            </nb-select>
                        </div>
                        <div class="col-sm-3">
                            <label for="dimProviders">Provider</label>
                            <nb-select id="dimProviders" name="dimProviders" [(ngModel)]="dimInstance.dimProviderKey"
                                required fullWidth>
                                <nb-option *ngFor="let provider of dimProviders" [value]="provider.dimProviderKey">
                                    {{provider.providerName}}
                                </nb-option>
                            </nb-select>
                        </div>
                        <div class="col-sm-3">
                            <label for="lastCollected">Last Collected</label>
                            <input nbInput id="lastCollected" [nbDatepicker]="lastCollected" name="lastCollected"
                                class="form-control" [(ngModel)]="dimInstance.lastCollected" fullWidth>
                            <nb-datepicker #lastCollected></nb-datepicker>
                        </div>
                        <div class="col-sm-3">
                            <label for="active">Active</label>
                            <nb-select id="active" name="active" [(ngModel)]="dimInstance.active" fullWidth>
                                <nb-option [value]="true">True</nb-option>
                                <nb-option [value]="false">False</nb-option>
                            </nb-select>
                        </div>
                        <div class="col-sm-3">
                            <label for="isSubscription">Is Subscription</label>
                            <nb-select id="isSubscription" name="isSubscription"
                                [(ngModel)]="dimInstance.isSubscription" fullWidth>
                                <nb-option [value]="true">True</nb-option>
                                <nb-option [value]="false">False</nb-option>
                            </nb-select>
                        </div>
                        <div class="col-sm-9">
                            <label for="emailAddresses">Email Address</label>
                            <input nbInput id="emailAddresses" name="emailAddresses" class="form-control"
                                [(ngModel)]="dimInstance.emailAddresses" fullWidth>
                        </div>
                    </div>
                </form>
            </nb-card-body>
            <nb-card-footer>
                <button class="btn btn-success" [disabled]="isFormValid() === false" (click)="save()">Save</button>
                <button class="btn btn-outline-dark ms-2" (click)="cancel()">Cancel</button>
            </nb-card-footer>
        </nb-card>
    </nb-layout-column>
</nb-layout>