import { DimClientModel } from "./dim-client.model";
import { DimPlatformModel } from "./dim-platform.model";
import { DimProviderModel } from "./dim-provider.model";

export class DimInstanceModel {
    dimInstanceKey!: number;
    dimClientKey!: number | null;
    instance!: string;
    serverName!: string;
    environment!: string;
    dimPlatformKey!: number;
    dimProviderKey!: number;
    lastCollected!: Date | null;
    lastCollectedString!: string | null;
    active!: boolean | null;
    isSubscription!: boolean | null;
    emailAddresses!: string;
    dimClientModel!: DimClientModel;
    dimPlatformModel!: DimPlatformModel;
    dimProviderModel!: DimProviderModel;
}