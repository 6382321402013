<nb-layout>
  <nb-layout-column>
    <nb-card>
      <nb-card-header>
        <app-header></app-header>
      </nb-card-header>
    </nb-card>
    <nb-card>
      <nb-card-header>
        SQL Instances
      </nb-card-header>
      <nb-card-body>
        <div class="table-container">
          <!-- <button class="btn btn-success" (click)="add()">Add</button> -->
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Id</th>
                <th>Client</th>
                <th>Server Name</th>
                <th>Instance</th>                
                <th>Environment</th>
                <th>Platform</th>
                <th>Provider</th>
                <th>Last Collected</th>
                <th>Active</th>
                <th>Is Subscription</th>
                <th>Email Address</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let dimInstanceModel of dimInstanceModels | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>
                  <div class="button-group">
                    <button class="btn btn-primary" (click)="edit(dimInstanceModel)">Edit</button>
                    <!-- <button class="btn btn-danger"
                  (click)="onDelete(dimInstanceModel.dimInstanceKey, dialog)">Delete</button> -->
                  </div>
                </td>
                <td>{{ dimInstanceModel.dimInstanceKey }}</td>
                <td>{{ dimInstanceModel.dimClientModel.client }}</td>
                <td>{{ dimInstanceModel.serverName }}</td>
                <td>{{ dimInstanceModel.instance }}</td>                
                <td>{{ dimInstanceModel.environment }}</td>
                <td>{{ dimInstanceModel.dimPlatformModel.platformName }}</td>
                <td>{{ dimInstanceModel.dimProviderModel.providerName }}</td>
                <td>{{ dimInstanceModel.lastCollectedString }}</td>
                <td>{{ dimInstanceModel.active }}</td>
                <td>{{ dimInstanceModel.isSubscription }}</td>
                <td>{{ dimInstanceModel.emailAddresses }}</td>
              </tr>
            </tbody>
          </table>
          <pagination-controls (pageChange)="currentPage = $event" previousLabel="Prev"
            nextLabel="Next"></pagination-controls>
        </div>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Confirm</nb-card-header>
    <nb-card-body>Are you sure you want to delete dim instance {{data.dimInstanceKey}}</nb-card-body>
    <nb-card-footer class="text-center">
      <button nbButton status="primary" (click)="delete(data, ref)">Yes</button>&nbsp;<button nbButton
        (click)="ref.close()">No</button>
    </nb-card-footer>
  </nb-card>
</ng-template>