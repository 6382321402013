import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DimClientModel } from 'src/app/models/dim-client.model';
import { DimInstanceModel } from 'src/app/models/dim-instance.model';
import { DimPlatformModel } from 'src/app/models/dim-platform.model';
import { DimProviderModel } from 'src/app/models/dim-provider.model';
import { DimInstanceService } from 'src/app/services/dim-instance.service';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {

  @ViewChild("dimInstanceForm") dimInstanceForm!: NgForm;

  dimInstance: DimInstanceModel = new DimInstanceModel();
  dimClients: DimClientModel[] = [];
  dimProviders: DimProviderModel[] = [];
  dimPlatforms: DimPlatformModel[] = [];
  isAdd!: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dimInstanceService: DimInstanceService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.dimInstance.dimInstanceKey = +params['id'];

      this.isAdd = this.dimInstance.dimInstanceKey == 0 ? true : false;

      this.getClients();
      this.getPlatforms();
      this.getProviders();

      if (!this.isAdd)
        this.getDimInstance();
    })
  }

  getDimInstance() {
    this.dimInstanceService.getDimInstance(this.dimInstance.dimInstanceKey).subscribe(data => {
      this.dimInstance = data;
      if (this.dimInstance.lastCollected) {
        let utcDate = new Date(this.dimInstance.lastCollected! + 'Z');
        this.dimInstance.lastCollected = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
      }
    });
  }

  getProviders() {
    this.dimInstanceService.getProviders().subscribe(data => {
      this.dimProviders = data;
    });
  }

  getPlatforms() {
    this.dimInstanceService.getPlatforms().subscribe(data => {
      this.dimPlatforms = data;
    });
  }

  getClients() {
    this.dimInstanceService.getClients().subscribe(data => {
      this.dimClients = data;
    });
  }

  isFormValid() {
    if (this.dimInstanceForm)
      return this.dimInstanceForm.valid;
    else
      return;
  }

  submit() {
    if (this.isAdd)
      this.dimInstanceService.addDimInstance(this.dimInstance).subscribe(response => {
        this.router.navigate(['Grid']);
      });
    else
      this.dimInstanceService.editDimInstance(this.dimInstance).subscribe(response => {
        this.router.navigate(['Grid']);
      });
  }

  save() {
    this.dimInstanceForm.ngSubmit.emit();
  }

  cancel() {
    this.router.navigate(['Grid']);
  }
}
